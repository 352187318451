.main {
  width: 98vw;
}

.PopoverPanel::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 8px 8px;
  border-style: solid;
  border-color: #1f2937 transparent;
}

div.syntaxhighlighter {
  font-size: 80% !important;
}

body {
  overflow-y: scroll;
}

.formatted-article-body {
  /*border: 5px solid red;*/
}

.formatted-article-body ul {
  list-style-type: disc;
  margin-left: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}


.formatted-article-body a {
  color: #1895fd;
  text-decoration: underline;
}

.formatted-article-body ul li {
  margin-bottom: 0.5rem;
}

.formatted-article-body h2 {
  font-size: 1.7rem;
  font-weight: bold;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  color: var(--tw-text-gray-900);
}

.formatted-article-body h3 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  color: var(--tw-text-gray-900);
}

.formatted-article-body h1 {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  line-height: 1.2;
  color: var(--tw-text-gray-900);
  margin-top: 0.5rem;
}
.formatted-article-body h1:not(:first-child) {
  margin-top: 2rem;
}

.formatted-article-body p {
  margin-bottom: 0.75rem;
}

/* scrollbar hidden unless hovered */
.ui-scroll::-webkit-scrollbar {
  width: 8px;
}

.ui-scroll::-webkit-scrollbar:hover {
  border: 1px solid #e5e5e5;
}

.ui-scroll:hover::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d5d5d5;
}

.ui-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0);
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.animate-wiggle {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  /* When the animation is finished, start again */
  animation-iteration-count: 1;
}
